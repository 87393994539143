<template>
  <div
    class="container-product-outfit-detail"
    :style="
      productHover && idProductHover != product.producto_sku + product.color
        ? 'opacity: .5; box-shadow: none;'
        : ''
    "
  >
    <div class="col-4 col-md-3">
      <img
        class="img-product-detail-outfit"
        :src="product.imagenes[0]"
        alt=""
        @click="showModalProduct()"
      />
    </div>
    <div class="col-8 col-md-9 data-product-outfit-detail">
      <p class="title-product-outfir-detail" @click="showModalProduct()">
        {{
          product.producto_titulo
            .toLowerCase()
            .replace(/^\w/, d => d.toUpperCase())
        }}
      </p>
      <span class="sku-color-product-outfir-detail"
        >SKU: {{ product.producto_sku }} <br />
        Color:
        {{
          product.color_nombre
            .toLowerCase()
            .replace(/^\w/, d => d.toUpperCase())
        }}
      </span>
      <div v-if="product.dia_siniva == 1" class="d-flex">
        <del
          >${{
            new Intl.NumberFormat('de-DE').format(product.producto_precio)
          }}</del
        >
        <p
          class="text-end mt-2 ms-3"
          style="line-height: .8; font-size: 1.1rem;"
        >
          <b
            >${{
              new Intl.NumberFormat('de-DE').format(
                product.producto_precio_siniva,
              )
            }}</b
          >
          <br />
          <span style="font-size: 11px">*Precio sin IVA</span>
        </p>
      </div>
      <p v-else class="producto_precio_siniva">
        ${{ new Intl.NumberFormat('de-DE').format(product.producto_precio) }}
      </p>
    </div>
    <button-fav class="store-fav" :data-product="product" />
    <modal-product-detail
      :product="product"
      :dataga4="{
        nameCreative: `outfit-detail-${outfitDetail.campania_slug}`,
        slot: `outfit-detail-page`,
        id: `${outfitDetail.campania_descripcion}`,
        name: `${outfitDetail?.nombre}`,
      }"
      class="w-100"
    />
    <div
      class="container-img-show-product-detail-outfit"
      @click="showModalProduct()"
    >
      <img
        style="min-height: 26px; min-width: 21px;"
        src="@/assets/icons/shopping-bag.svg"
      />
    </div>
  </div>
</template>
<script>
import ButtonFav from '@/components/Templates/ButtonFav'
import ModalProductDetail from '@/components/Templates/ModalProductDetail.vue'
import { mapState } from 'vuex'
import { GA4SelectPromotion } from '@/GA4/events'
export default {
  components: { ButtonFav, ModalProductDetail },
  props: {
    product: {
      type: Object,
      required: false,
      default: () => {},
    },
    productHover: {
      type: Boolean,
      required: false,
    },
    idProductHover: {
      type: String,
      required: false,
      default: '',
    },
    showModal: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    ...mapState('outfits', ['outfitDetail']),
  },
  methods: {
    showModalProduct() {
      this.$bvModal.show('modal-product-detail-' + this.product.producto_sku)
      GA4SelectPromotion({
        nameCreative: `outfit-detail-${this.outfitDetail.campania_slug}`,
        slot: `outfit-detail-page`,
        id: `${this.outfitDetail.campania_descripcion}`,
        name: `${this.outfitDetail?.nombre}`,
        items: [
          {
            item_name: this.product.producto_titulo,
            item_id: this.product.producto_sku,
            price: parseInt(this.product.producto_precio),
            affiliation: 'Derek',
            item_brand: this.product.marca,
            item_category: this.product.categoria,
            item_category2: this.product.subcategoria,
            item_list_id: this.$route.params.idCampaign,
            item_variant: this.product.color,
            discount:
              parseInt(this.product.producto_precio_antes) > 0
                ? parseInt(this.product.producto_precio_antes) -
                  parseInt(this.product.producto_precio)
                : 0,
            promotion_name: this.product.descuento
              ? this.product.descuento
              : '',
            quantity: 1,
          },
        ],
      })
    },
  },
}
</script>
