var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-max-width"},[(_vm.outfitDetail)?_c('div',{staticClass:"container-outfit-detail"},[_c('div',{staticClass:"col-12 col-md-6 mb-auto container-galeria"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showVideo),expression:"!showVideo"}],staticClass:"img-outfit-detail"},[_c('img',{attrs:{"src":_vm.outfitDetail.imagenes_outfit[_vm.indexImgSelect],"alt":""}}),_vm._l((_vm.outfitDetail.productos),function(coordenadas,index){return _c('span',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(coordenadas.producto_titulo),expression:"coordenadas.producto_titulo",modifiers:{"hover":true,"top":true}},{name:"b-hover",rawName:"v-b-hover",value:(_vm.hoverproduct),expression:"hoverproduct"}],key:index,staticClass:"pointer-product d-none d-md-flex",style:('top: ' +
              coordenadas.coordenada_y +
              '%; ' +
              'left: ' +
              coordenadas.coordenada_x +
              '%;'),on:{"mouseover":function($event){_vm.productoSkuColor = coordenadas.producto_sku + coordenadas.color},"click":function($event){return _vm.clicTooltip(coordenadas)}}})}),_vm._l((_vm.outfitDetail.productos),function(coordenadas,index){return _c('span',{key:index + 'movil',staticClass:"pointer-product d-flex d-md-none",style:('top: ' +
              coordenadas.coordenada_y +
              '%; ' +
              'left: ' +
              coordenadas.coordenada_x +
              '%;'),on:{"click":function($event){_vm.showModal = true
            _vm.$bvModal.show('modal-product-detail-' + coordenadas.producto_sku)}}})})],2),_c('div',{staticClass:"d-flex flex-wrap"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showVideo),expression:"showVideo"}],staticClass:"img-outfit-detail"},[(_vm.outfitDetail.video)?_c('video',{staticClass:"w-100",attrs:{"id":"video-producto","controls":"","preload":"","loop":""}},[_c('source',{attrs:{"src":_vm.outfitDetail.video,"type":"video/mp4"}})]):_vm._e()])]),_c('div',{staticClass:"mt-3 d-flex flex-wrap"},[_c('div',{staticClass:"container-imgs-select"},_vm._l((_vm.outfitDetail.imagenes_outfit),function(img,index){return _c('img',{key:index,class:!_vm.showVideo && _vm.indexImgSelect == index ? 'img-select' : '',attrs:{"src":img},on:{"click":function($event){_vm.indexImgSelect = index
              _vm.showVideo = false}}})}),0),(_vm.outfitDetail.video)?_c('div',{staticClass:"container-imgs-select"},[_c('div',{staticClass:"video-product",class:_vm.showVideo ? 'img-select' : '',staticStyle:{"background-color":"white","margin-bottom":"0"}},[_c('div',{staticClass:"play-video",on:{"click":_vm.playVideo}},[_c('img',{staticClass:"play-video-product-detail",attrs:{"src":require("@/assets/icons/play-video.svg"),"alt":""}}),_c('p',{staticClass:"mb-0"},[_vm._v("Ver video")])])])]):_vm._e()])]),_c('div',{staticClass:"col-12 col-md-6 data-outfit-detail"},[_c('div',{staticClass:"container-data-outfit-detail"},[_c('div',{staticClass:"float-end"},[_c('span',{staticClass:"id-card-outfit"},[_vm._v(_vm._s(_vm.outfitDetail.id))])]),_c('div',{staticClass:"px-0 py-4 p-md-4 mt-3 mt-md-0"},[_c('h2',{staticClass:"title-card-outfit"},[_vm._v(_vm._s(_vm.outfitDetail?.nombre))]),_c('p',{staticClass:"is-card-outfit"},[_vm._v(" "+_vm._s(_vm.outfitDetail.campania_descripcion)+" ")]),_c('p',{staticClass:"is-card-outfit"},[_vm._v(_vm._s(_vm.outfitDetail.descripcion))])])]),_vm._l((_vm.outfitDetail.productos),function(product,index){return _c('div',{key:index,staticClass:"ps-0 ps-md-4 col-12 col-md-11 col-xl-9 mb-4"},[_c('product-outfit-detail',{attrs:{"product":product,"product-hover":_vm.productoHover,"id-product-hover":_vm.productoSkuColor,"show-modal":_vm.showModal}})],1)})],2),_c('add-to-cart')],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }