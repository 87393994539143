var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-product-outfit-detail",style:(_vm.productHover && _vm.idProductHover != _vm.product.producto_sku + _vm.product.color
      ? 'opacity: .5; box-shadow: none;'
      : '')},[_c('div',{staticClass:"col-4 col-md-3"},[_c('img',{staticClass:"img-product-detail-outfit",attrs:{"src":_vm.product.imagenes[0],"alt":""},on:{"click":function($event){return _vm.showModalProduct()}}})]),_c('div',{staticClass:"col-8 col-md-9 data-product-outfit-detail"},[_c('p',{staticClass:"title-product-outfir-detail",on:{"click":function($event){return _vm.showModalProduct()}}},[_vm._v(" "+_vm._s(_vm.product.producto_titulo .toLowerCase() .replace(/^\w/, d => d.toUpperCase()))+" ")]),_c('span',{staticClass:"sku-color-product-outfir-detail"},[_vm._v("SKU: "+_vm._s(_vm.product.producto_sku)+" "),_c('br'),_vm._v(" Color: "+_vm._s(_vm.product.color_nombre .toLowerCase() .replace(/^\w/, d => d.toUpperCase()))+" ")]),(_vm.product.dia_siniva == 1)?_c('div',{staticClass:"d-flex"},[_c('del',[_vm._v("$"+_vm._s(new Intl.NumberFormat('de-DE').format(_vm.product.producto_precio)))]),_c('p',{staticClass:"text-end mt-2 ms-3",staticStyle:{"line-height":".8","font-size":"1.1rem"}},[_c('b',[_vm._v("$"+_vm._s(new Intl.NumberFormat('de-DE').format( _vm.product.producto_precio_siniva, )))]),_c('br'),_c('span',{staticStyle:{"font-size":"11px"}},[_vm._v("*Precio sin IVA")])])]):_c('p',{staticClass:"producto_precio_siniva"},[_vm._v(" $"+_vm._s(new Intl.NumberFormat('de-DE').format(_vm.product.producto_precio))+" ")])]),_c('button-fav',{staticClass:"store-fav",attrs:{"data-product":_vm.product}}),_c('modal-product-detail',{staticClass:"w-100",attrs:{"product":_vm.product,"dataga4":{
      nameCreative: `outfit-detail-${_vm.outfitDetail.campania_slug}`,
      slot: `outfit-detail-page`,
      id: `${_vm.outfitDetail.campania_descripcion}`,
      name: `${_vm.outfitDetail?.nombre}`,
    }}}),_c('div',{staticClass:"container-img-show-product-detail-outfit",on:{"click":function($event){return _vm.showModalProduct()}}},[_c('img',{staticStyle:{"min-height":"26px","min-width":"21px"},attrs:{"src":require("@/assets/icons/shopping-bag.svg")}})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }