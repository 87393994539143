<template>
  <div class="container-max-width">
    <div v-if="outfitDetail" class="container-outfit-detail">
      <div class="col-12 col-md-6 mb-auto container-galeria">
        <div v-show="!showVideo" class="img-outfit-detail">
          <img :src="outfitDetail.imagenes_outfit[indexImgSelect]" alt="" />
          <span
            v-for="(coordenadas, index) in outfitDetail.productos"
            :key="index"
            v-b-popover.hover.top="coordenadas.producto_titulo"
            v-b-hover="hoverproduct"
            class="pointer-product d-none d-md-flex"
            :style="
              'top: ' +
                coordenadas.coordenada_y +
                '%; ' +
                'left: ' +
                coordenadas.coordenada_x +
                '%;'
            "
            @mouseover="
              productoSkuColor = coordenadas.producto_sku + coordenadas.color
            "
            @click="clicTooltip(coordenadas)"
          ></span>
          <span
            v-for="(coordenadas, index) in outfitDetail.productos"
            :key="index + 'movil'"
            class="pointer-product d-flex d-md-none"
            :style="
              'top: ' +
                coordenadas.coordenada_y +
                '%; ' +
                'left: ' +
                coordenadas.coordenada_x +
                '%;'
            "
            @click="
              showModal = true
              $bvModal.show('modal-product-detail-' + coordenadas.producto_sku)
            "
          ></span>
        </div>
        <div class="d-flex flex-wrap">
          <div v-show="showVideo" class="img-outfit-detail">
            <video
              v-if="outfitDetail.video"
              id="video-producto"
              class="w-100"
              controls
              preload
              loop
            >
              <source :src="outfitDetail.video" type="video/mp4" />
            </video>
          </div>
        </div>
        <div class="mt-3 d-flex flex-wrap">
          <div class="container-imgs-select">
            <img
              v-for="(img, index) in outfitDetail.imagenes_outfit"
              :key="index"
              :class="!showVideo && indexImgSelect == index ? 'img-select' : ''"
              :src="img"
              @click="
                indexImgSelect = index
                showVideo = false
              "
            />
          </div>
          <div v-if="outfitDetail.video" class="container-imgs-select">
            <div
              class="video-product"
              :class="showVideo ? 'img-select' : ''"
              style="background-color: white;margin-bottom: 0"
            >
              <div class="play-video" @click="playVideo">
                <img
                  src="@/assets/icons/play-video.svg"
                  class="play-video-product-detail"
                  alt=""
                />
                <p class="mb-0">Ver video</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 data-outfit-detail">
        <div class="container-data-outfit-detail">
          <div class="float-end">
            <span class="id-card-outfit">{{ outfitDetail.id }}</span>
          </div>
          <div class="px-0 py-4 p-md-4 mt-3 mt-md-0">
            <h2 class="title-card-outfit">{{ outfitDetail?.nombre }}</h2>
            <p class="is-card-outfit">
              {{ outfitDetail.campania_descripcion }}
            </p>
            <p class="is-card-outfit">{{ outfitDetail.descripcion }}</p>
          </div>
        </div>
        <div
          v-for="(product, index) in outfitDetail.productos"
          :key="index"
          class="ps-0 ps-md-4 col-12 col-md-11 col-xl-9 mb-4"
        >
          <product-outfit-detail
            :product="product"
            :product-hover="productoHover"
            :id-product-hover="productoSkuColor"
            :show-modal="showModal"
          />
        </div>
      </div>
      <add-to-cart />
    </div>
  </div>
</template>
<script>
import AddToCart from '@/components/ProductDetail/AddToCart.vue'
import ProductOutfitDetail from '@/components/Outfit/ProductOutfitDetail.vue'
import { GA4SelectPromotion, GA4ViewPromotion } from '@/GA4/events'

import { mapActions, mapState } from 'vuex'
export default {
  components: { ProductOutfitDetail, AddToCart },
  data() {
    return {
      productoSkuColor: '',
      productoHover: false,
      showModal: false,
      showVideo: false,
      indexImgSelect: 0,
    }
  },
  computed: {
    ...mapState('outfits', ['outfitDetail']),
    ...mapState('products', ['product']),
  },
  watch: {
    outfitDetail() {
      document.title =
        'Outfit ' +
        this.outfitDetail?.nombre.toLowerCase() +
        ' | DEREK Tienda Online'
      const GA4Products = []
      this.outfitDetail.productos.forEach(product =>
        GA4Products.push({
          item_name: product.producto_titulo,
          item_id: product.producto_sku,
          price: parseInt(product.producto_precio),
          affiliation: 'Derek',
          item_brand: 'Derek',
          item_category: product.categoria,
          item_category2: product.subcategoria,
          item_list_id: this.$route.params.idCampaign,
          item_variant: product.color,
          discount:
            parseInt(product.producto_precio_antes) > 0
              ? parseInt(product.producto_precio_antes) -
                parseInt(product.producto_precio)
              : 0,
          promotion_name: product.descuento ? product.descuento : '',
          quantity: 1,
        }),
      )
      if (this.$route.params.idCampaign) {
        GA4SelectPromotion({
          nameCreative: `${this.$route.params.nameCreative}`,
          slot: `${this.$route.params.slot}`,
          id: `${this.$route.params.idCampaign}`,
          name: `${this.$route.params.name}`,
          items: GA4Products,
        })
      } else {
        GA4ViewPromotion({
          nameCreative: `outfit-detail-${this.outfitDetail.campania_slug}`,
          slot: `outfit-detail-page`,
          id: `${this.outfitDetail.campania_descripcion}`,
          name: `${this.outfitDetail?.nombre}`,
          items: GA4Products,
        })
      }
    },
    showVideo() {
      window.scrollTo(0, 0)
    },
    indexImgSelect() {
      window.scrollTo(0, 0)
    },
  },
  mounted() {
    this.getDetailOutfit({ id: this.$route.params.id })
    // this.$gtag.pageview(this.$route.fullPath)
    document.title = 'Cargando outfit...'
  },
  methods: {
    ...mapActions('outfits', ['getDetailOutfit']),
    hoverproduct(hovered) {
      if (!hovered) {
        this.productoSkuColor = ''
        this.showModal = false
      }
      this.productoHover = hovered
    },
    playVideo() {
      this.showVideo = !this.showVideo
      let video = document.getElementById('video-producto')
      video.play()
      video.currentTime = 0
    },
    async listenerExitVideo(id_video) {
      let video = document.getElementById(id_video)
      await setTimeout(() => {
        if (
          document.fullscreenElement ||
          document.webkitFullscreenElement ||
          document.mozFullScreenElement ||
          document.webkitCurrentFullScreenElement
        ) {
          this.listenerExitVideo(id_video)
        } else {
          video.pause()
        }
      }, 1000)
    },
    clicTooltip(coordenadas) {
      this.showModal = true
      this.$bvModal.show('modal-product-detail-' + coordenadas.producto_sku)
      let productAnalitycs = this.outfitDetail.productos_analytics?.find(
        e => e.id == coordenadas.producto_sku,
      )
      if (productAnalitycs) {
        // DK-VentaCruzada
        // window.dataLayer.push({
        //   event: 'DK-VentaCruzada',
        //   productoPadre: this.dataProduct.titulo,
        //   productoPadreSKU: this.dataProduct.producto_sku,
        //   item: productAnalitycs,
        //   value: productAnalitycs.price,
        // })
        // this.$gtag.event('productClick', {
        //   ecommerce: {
        //     click: {
        //       actionField: { list: productAnalitycs.category },
        //       products: [
        //         {
        //           name: productAnalitycs.name,
        //           id: productAnalitycs.id,
        //           price: productAnalitycs.price,
        //           brand: productAnalitycs.brand,
        //           category: productAnalitycs.category,
        //           variant: productAnalitycs.variant,
        //           position: productAnalitycs.position,
        //         },
        //       ],
        //     },
        //   },
        // })
      }
    },
  },
}
</script>
